import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "infofinland"
    }}>{`infoFinland`}</h1>
    <p>{`The infoFinland API provides an interface to the content of infopankki.fi website`}</p>
    <ul>
      <li parentName="ul">{`API location: `}<a parentName="li" {...{
          "href": "https://api.infofinland.fi/"
        }}>{`api.infofinland.fi`}</a></li>
    </ul>
    <h2 {...{
      "id": "information"
    }}>{`Information`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://infopankki.fi/"
        }}>{`infoFinland website`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/City-of-Helsinki/infopankki-api"
        }}>{`API application's source code on Github`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.hri.fi/en/dataset/tietoa-suomesta-monella-eri-kielella"
        }}>{`infoFinland API description at HRI`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      